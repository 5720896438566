export const NFTs: string[] = [];

export const PlianContractAddress721Main: string =
  "0xa2822ac2662fe0cbf470d5721e24f8508ec43d33";
export const OPContractAddress721Main: string =
  "0x1e0049783f008a0085193e00003d00cd54003c71";
export const PlianContractAddressMarketMain: string =
  "0x286d1ae7c43c5ea3c7bbee601d9c1af875f5c50e";
export const PlianContractAddress721Test: string =
  "0x6B2793D4024bC3A9505133c0649e194488be1a47";
export const PlianContractAddressMarketTest: string =
  "0xEDC36347d13f7838edCC3f760908c0Cf06C3aCF8";
export const PlianContractERC20Test: string =
  "0xFcb06A1a2E8834Fe9E0b49F533E14AB6384f74AC";
export const PlianContractSBTTest: string =
  "0x27e67a318f41d7475f409f4a390084b6aa16ac50";
export const SystemAddress: string =
  "0x0000000000000000000000000000000000000000";
export const PNFTAddress = "0x10401b9A7E93E10aC92E7bB55Ae87433B9E01e08";
export const MintV2ContractAddress =
  "0xd79c5f4973fe62eb1D334C70F4F9D87a2BD738B5";

export const TaikoContractAddress721Main: string =
  "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E";
export const TaikoContractAddress721Test: string =
  "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E";
export const TaikoContractAddressMarketTest: string =
  "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb";
export const TaikoContractAddressMarketMain: string =
  "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb";
export const TaikoContractAddressERC20Test: string =
  "0x6302744962a0578e814c675b40909e64d9966b0d";
export const VERSION: string = process.env.REACT_APP_VERSION as string;
export const SOLERC20: string = "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF";
export const CreateCollectionAddress: string =
  "0xccF09e95F7578A2B5576E6C745ac12a53b3a6031";
export const MemeAddress: string = "0xe4f68a76fB148014e8c08aFF3875E9544D8E0688";

interface Model {
  value: string;
  label: string;
}
export interface Company {
  logo: string;
  url: string;
}

export const Model: Model[] = [
  {
    value: "1",
    label: "Afrikaans",
  },
  {
    value: "2",
    label: "Amharic",
  },
  {
    value: "3",
    label: "Aragonese",
  },
  {
    value: "4",
    label: "Arabic",
  },
  {
    value: "5",
    label: "Assamese",
  },
  {
    value: "6",
    label: "Azerbaijani",
  },
  {
    value: "7",
    label: "Bulgarian",
  },
  {
    value: "8",
    label: "Bengali",
  },
  {
    value: "9",
    label: "Bishnupriya*Manipuri",
  },
  {
    value: "10",
    label: "Bosnian",
  },
  {
    value: "11",
    label: "Catalan",
  },
  {
    value: "12",
    label: "Chinese*(Mandarin)",
  },
  {
    value: "13",
    label: "Czech",
  },
  {
    value: "14",
    label: "Welsh",
  },
  {
    value: "15",
    label: "Danish",
  },
  {
    value: "16",
    label: "German",
  },
  {
    value: "17",
    label: "Greek",
  },
  {
    value: "18",
    label: "English*(Caribbean)",
  },
  {
    value: "19",
    label: "English(GreatBritain)",
  },
  {
    value: "20",
    label: "English*(Scotland)",
  },
  {
    value: "21",
    label: "English*(Lancaster)",
  },
  {
    value: "22",
    label: "English(WestMidlands)",
  },
  {
    value: "23",
    label: "English(ReceivedPronunciation)",
  },
  {
    value: "24",
    label: "English*(America)",
  },
  {
    value: "25",
    label: "Esperanto",
  },
  {
    value: "26",
    label: "Spanish*(Spain)",
  },
  {
    value: "27",
    label: "Spanish(LatinAmerica)",
  },
  {
    value: "28",
    label: "Estonian",
  },
  {
    value: "29",
    label: "Basque",
  },
  {
    value: "30",
    label: "Persian",
  },
  {
    value: "31",
    label: "Persian*(Pinglish)",
  },
  {
    value: "32",
    label: "Finnish",
  },
  {
    value: "33",
    label: "French*(Belgium)",
  },
  {
    value: "34",
    label: "French*(Switzerland)",
  },
  {
    value: "35",
    label: "French*(France)",
  },
  {
    value: "36",
    label: "Gaelic*(Irish)",
  },
  {
    value: "37",
    label: "Gaelic*(Scottish)",
  },
  {
    value: "38",
    label: "Guarani",
  },
  {
    value: "39",
    label: "Greek*(Ancient)",
  },
  {
    value: "40",
    label: "Gujarati",
  },
  {
    value: "41",
    label: "Hindi",
  },
  {
    value: "42",
    label: "Croatian",
  },
  {
    value: "43",
    label: "Hungarian",
  },
  {
    value: "44",
    label: "Armenian*(East_Armenia)",
  },
  {
    value: "45",
    label: "Armenian(WestArmenia)",
  },
  {
    value: "46",
    label: "Interlingua",
  },
  {
    value: "47",
    label: "Indonesian",
  },
  {
    value: "48",
    label: "Icelandic",
  },
  {
    value: "49",
    label: "Italian",
  },
  {
    value: "50",
    label: "Japanese",
  },
  {
    value: "51",
    label: "Lojban",
  },
  {
    value: "52",
    label: "Georgian",
  },
  {
    value: "53",
    label: "Greenlandic",
  },
  {
    value: "54",
    label: "Kannada",
  },
  {
    value: "55",
    label: "Korean",
  },
  {
    value: "56",
    label: "Konkani",
  },
  {
    value: "57",
    label: "Kurdish",
  },
  {
    value: "58",
    label: "Kyrgyz",
  },
  {
    value: "59",
    label: "Latin",
  },
  {
    value: "60",
    label: "Lingua_Franca_Nova",
  },
  {
    value: "61",
    label: "Lithuanian",
  },
  {
    value: "62",
    label: "Latvian",
  },
  {
    value: "63",
    label: "poz/mi",
  },
  {
    value: "64",
    label: "Macedonian",
  },
  {
    value: "65",
    label: "Malayalam",
  },
  {
    value: "66",
    label: "Marathi",
  },
  {
    value: "67",
    label: "Malay",
  },
  {
    value: "68",
    label: "Maltese",
  },
  {
    value: "69",
    label: "Burmese",
  },
  {
    value: "70",
    label: "Norwegian_Bokmål",
  },
  {
    value: "71",
    label: "Nahuatl*(Classical)",
  },
  {
    value: "72",
    label: "Nepali",
  },
  {
    value: "73",
    label: "Dutch",
  },
  {
    value: "74",
    label: "Oromo",
  },
  {
    value: "75",
    label: "Oriya",
  },
  {
    value: "76",
    label: "Punjabi",
  },
  {
    value: "77",
    label: "Papiamento",
  },
  {
    value: "78",
    label: "Polish",
  },
  {
    value: "79",
    label: "Portuguese*(Portugal)",
  },
  {
    value: "80",
    label: "Portuguese*(Brazil)",
  },
  {
    value: "81",
    label: "Romanian",
  },
  {
    value: "82",
    label: "Russian",
  },
  {
    value: "83",
    label: "Sindhi",
  },
  {
    value: "84",
    label: "Sinhala",
  },
  {
    value: "85",
    label: "Slovak",
  },
  {
    value: "86",
    label: "Slovenian",
  },
  {
    value: "87",
    label: "Albanian",
  },
  {
    value: "88",
    label: "Serbian",
  },
  {
    value: "89",
    label: "Swedish",
  },
  {
    value: "90",
    label: "Swahili",
  },
  {
    value: "91",
    label: "Tamil",
  },
  {
    value: "92",
    label: "Telugu",
  },
  {
    value: "93",
    label: "Setswana",
  },
  {
    value: "94",
    label: "Turkish",
  },
  {
    value: "95",
    label: "Tatar",
  },
  {
    value: "96",
    label: "Urdu",
  },
  {
    value: "97",
    label: "Vietnamese*(Northern)",
  },
  {
    value: "98",
    label: "Vietnamese*(Central)",
  },
  {
    value: "99",
    label: "Vietnamese*(Southern)",
  },
  {
    value: "100",
    label: "Chinese*(Cantonese)",
  },
];

export interface Config {
  chain_id: string;
  contract_721: string;
  contract_721_test: string;
  contract_market: string;
  contract_market_test: string;
  contract_erc20: string;
  chain_name: string;
  chain_logo: string;
}
export interface ConfigName {
  chain_id: string;
  chain_name: string;
  chain_logo: string;
  fee: string;
  gas: string;
  token: {
    symbol: string;
    icon: string;
    address: string;
    fee: string;
  }[];
}

export const NetworkConfig: Config[] = [
  {
    chain_id: "8007736",
    chain_name: "Plian Subchain 1",
    chain_logo: require("../assets/images/plian.logo.png"),
    contract_721: "0xa2822ac2662fe0cbf470d5721e24f8508ec43d33",
    // contract_721: '0xd79c5f4973fe62eb1D334C70F4F9D87a2BD738B5',
    contract_721_test: "0x6B2793D4024bC3A9505133c0649e194488be1a47",
    contract_market: "0x286d1ae7c43c5ea3c7bbee601d9c1af875f5c50e",
    contract_market_test: "0xEDC36347d13f7838edCC3f760908c0Cf06C3aCF8",
    contract_erc20: "0xFcb06A1a2E8834Fe9E0b49F533E14AB6384f74AC",
  },
  {
    chain_id:'8453',
    chain_name:'Base ',
    chain_logo:require('../assets/images/base.logo.png'),
    contract_721:'',
    contract_721_test:'',
    contract_market:'',
    contract_market_test:'',
    contract_erc20:''
  },
  {
    chain_id:'84532',
    chain_name:'Base Sepolia',
    chain_logo:require('../assets/images/base.logo.png'),
    contract_721:'',
    contract_721_test:'',
    contract_market:'',
    contract_market_test:'',
    contract_erc20:''
  },
  {
    chain_id: "1",
    chain_name: "Ethereum",
    chain_logo: require("../assets/images/eth.logo.png"),
    contract_721: "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E",
    contract_721_test: "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E",
    contract_market: "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb",
    contract_market_test: "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb",
    contract_erc20: "0x6302744962a0578e814c675b40909e64d9966b0d",
  },
  // {
  //     chain_id:'167005',
  //     chain_name:'Takio',
  //     contract_721:'0x169625caf5d3f14e8d9F680Db6923343b80d1B1E',
  //     contract_721_test:'0x169625caf5d3f14e8d9F680Db6923343b80d1B1E',
  //     contract_market:'0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb',
  //     contract_market_test:'0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb',
  //     contract_erc20:'0x6302744962a0578e814c675b40909e64d9966b0d'
  // },
  {
    chain_id: "314",
    chain_name: "Filecoin",
    chain_logo: require("../assets/images/fil.logo.png"),
    contract_721: "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb",
    contract_721_test: "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb",
    contract_market: "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb",
    contract_market_test: "0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb",
    contract_erc20: "0x6302744962a0578e814c675b40909e64d9966b0d",
  },
  {
    chain_id: "10",
    chain_name: "Optimism",
    chain_logo: require("../assets/images/op.logo.png"),
    contract_721: "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E",
    contract_721_test: "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E",
    contract_market: "0x1E0049783F008A0085193E00003D00cd54003c71",
    contract_market_test: "0x1E0049783F008A0085193E00003D00cd54003c71",
    contract_erc20: "0x6302744962a0578e814c675b40909e64d9966b0d",
  },
  {
    chain_id: "210425",
    chain_name: "PlatON",
    chain_logo: require("../assets/images/plat.logo.png"),
    contract_721: "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E",
    contract_721_test: "0x169625caf5d3f14e8d9F680Db6923343b80d1B1E",
    contract_market: "0x5B3Cb2Cdd18D5622987193cabbCf4526a56370fC",
    contract_market_test: "0x5B3Cb2Cdd18D5622987193cabbCf4526a56370fC",
    contract_erc20: "0x6302744962a0578e814c675b40909e64d9966b0d",
  },
];
export const NetworkConfigName: ConfigName[] = [
  {
    chain_id: "8007736",
    chain_name: "plians1",
    chain_logo: require("../assets/images/plian.logo.png"),
    fee: "0.4",
    token: [
      {
        symbol: "PI",
        icon: require("../assets/images/plian.logo.png"),
        address: SystemAddress,
        fee: "0.4",
      },
      {
        symbol: "PNFT",
        icon: require("../assets/images/pnft.png"),
        address: PNFTAddress,
        fee: "1",
      },
    ],
    gas: "4200000",
  },
  {
    chain_id: "8453",
    chain_name: "base",
    chain_logo: require("../assets/images/base.logo.png"),
    fee: "0.4",
    token: [
      {
        symbol: "PI",
        icon: require("../assets/images/plian.logo.png"),
        address: SystemAddress,
        fee: "0.4",
      },
    ],
    gas: "4200000",
  },
  {
    chain_id: "84532",
    chain_name: "base",
    chain_logo: require("../assets/images/base.logo.png"),
    fee: "0.4",
    token: [
      {
        symbol: "PI",
        icon: require("../assets/images/plian.logo.png"),
        address: SystemAddress,
        fee: "0.4",
      },
    ],
    gas: "4200000",
  },
  {
    chain_id: "1",
    chain_name: "ethereum",
    chain_logo: require("../assets/images/eth.logo.png"),
    fee: "0.0001",
    token: [
      {
        symbol: "ETH",
        icon: require("../assets/images/eth.logo.2.png"),
        address: SystemAddress,
        fee: "0.0001",
      },
    ],
    gas: "15000000",
  },
  // {
  //     chain_id:'167005',
  //     chain_name:'Takio',
  //     contract_721:'0x169625caf5d3f14e8d9F680Db6923343b80d1B1E',
  //     contract_721_test:'0x169625caf5d3f14e8d9F680Db6923343b80d1B1E',
  //     contract_market:'0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb',
  //     contract_market_test:'0xfeF733d1b4AeDf93A8AB195F4E20F9Da97dfd4Eb',
  //     contract_erc20:'0x6302744962a0578e814c675b40909e64d9966b0d'
  // },
  {
    chain_id: "314",
    chain_name: "filecoin",
    chain_logo: require("../assets/images/fil.logo.png"),
    fee: "0.01",
    token: [
      {
        symbol: "FIL",
        icon: require("../assets/images/fil.logo.png"),
        address: SystemAddress,
        fee: "0.01",
      },
    ],
    gas: "8000000",
  },
  {
    chain_id: "10",
    chain_name: "optimism",
    chain_logo: require("../assets/images/op.logo.png"),
    fee: "0.0001",
    token: [
      {
        symbol: "ETH",
        icon: require("../assets/images/eth.logo.op.png"),
        address: SystemAddress,
        fee: "0.0001",
      },
    ],
    gas: "15000000",
  },
  {
    chain_id: "210425",
    chain_name: "platon",
    chain_logo: require("../assets/images/plat.logo.png"),
    fee: "1",
    token: [
      {
        symbol: "LAT",
        icon: require("../assets/images/plat.logo.png"),
        address: SystemAddress,
        fee: "1",
      },
    ],
    gas: "4200000",
  },
];
export const ChainsToken: { symbol: string; logo: string }[] = [
  {
    symbol: "btc",
    logo: require("../assets/images/bitcoin.logo.png"),
  },
  {
    symbol: "sol",
    logo: require("../assets/images/solana.logo.png"),
  },
];
export const TokenInfo: { symbol: string; logo: string }[] = [
  {
    symbol: "ETH",
    logo: require("../assets/new/eth_logo.png"),
  },
  {
    symbol: "PI",
    logo: require("../assets/images/pi_logo.png"),
  },
  {
    symbol: "LAT",
    logo: require("../assets/images/plat.logo.png"),
  },
  {
    symbol: "PNFT",
    logo: require("../assets/images/pnft.png"),
  },
];
export const ChainInfo: { name: string; logo: string }[] = [
  {
    name: "8007736",
    logo: require("../assets/new/plian_logo.png"),
  },
  {
    name: "1",
    logo: require("../assets/new/eht_white_logo.png"),
  },
  {
    name: "314",
    logo: require("../assets/new/fil_white_logo.png"),
  },
  {
    name: "10",
    logo: require("../assets/new/op_white_logo.png"),
  },
  {
    name: "210425",
    logo: require("../assets/images/plat_white.png"),
  },
];

export const CompanyList: Company[] = [
  {
    logo: require("../assets/new/company/BYZNTINE.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/Blockvc.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/FAMSWAP.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/FBG.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/GENESIS.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/JRR.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/continue_capital.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/fil.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/linkvc.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/poly.png"),
    url: "",
  },
  {
    logo: require("../assets/new/company/taiko.png"),
    url: "",
  },
];

export const flag = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
